<template>
	<v-container fluid style="max-width: 1500px">
		<v-fade-transition hide-on-leave>
			<template v-if="loading">
				<v-skeleton-loader key="skeleton" type="list-item-avatar" min-width="1500" height="120" class="rounded-xl" />
			</template>
			<template v-else>
				<div class="d-flex justify-space-between flex-wrap">
					<v-row key="offerFirstRow">
						<v-col cols="12" md="2" sm="3" xs="5">
							<OfferLogo :loading-parent="loading" />
						</v-col>
						<v-col cols="12" md="10" sm="9" xs="7">
							<OfferInfo :loading-parent="loading" />
						</v-col>
					</v-row>
					<div class="d-flex justify-space-around" :class="{ 'flex-column': !$vuetify.breakpoint.smAndDown }">
						<OfferApplyButton :loading-parent="loading" />
						<OfferApplyButtonExternal :loading-parent="loading" />
					</div>
				</div>
			</template>
		</v-fade-transition>

		<v-row>
			<v-col cols="12" lg="4" md="6" xs="12">
				<OfferAbout :loading-parent="loading" />
			</v-col>
			<v-col cols="12" lg="4" md="6" xs="12">
				<OfferBenefits :loading-parent="loading" />
			</v-col>
			<v-col cols="12" lg="4" md="6" xs="12">
				<OfferNeeds :loading-parent="loading" />
			</v-col>

			<v-col v-show="offer.applied" md="8">
				<OfferDocuments :loading-parent="loading" />
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
	metaInfo() {
		return {
			title: this.title ? `${this.$t('offers.offer')} - ${this.title}` : this.$t('offers.offer'),
		}
	},
	data() {
		return {
			loading: true,
			title: '',
		}
	},
	components: {
		OfferLogo: () => import('@/components/offers/OfferLogo.vue'),
		OfferInfo: () => import('@/components/offers/OfferInfo.vue'),
		OfferApplyButton: () => import('@/components/offers/OfferApplyButton.vue'),
		OfferApplyButtonExternal: () => import('@/components/offers/OfferApplyButtonExternal.vue'),
		OfferAbout: () => import('@/components/offers/OfferAbout.vue'),
		OfferBenefits: () => import('@/components/offers/OfferBenefits.vue'),
		OfferNeeds: () => import('@/components/offers/OfferNeeds.vue'),
		OfferDocuments: () => import('@/components/offers/OfferDocuments.vue'),
	},
	watch: {
		'$route.params.id'(id) {
			this.loading = true
			this.fetchOffer(id).then(({ success }) => {
				if (success) this.title = this.offer.title
				this.loading = false
			})
		},
	},
	computed: {
		...mapGetters({
			offer: 'offers/offer',
		}),
	},
	mounted() {
		this.fetchOffer(this.$route.params.id).then(({ success }) => {
			if (success) this.title = this.offer.title
			this.loading = false
		})
	},
	methods: {
		...mapActions('offers', ['fetchOffer']),
	},
}
</script>
